import React from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Head from 'next/head';

const {
  FRONTEND_BASE_URL,
  IOS_APP_STORE_ID,
  ANDROID_PACKAGE_NAME,
} = getConfig().publicRuntimeConfig;

const deepLinkUrlPart = FRONTEND_BASE_URL.replace(
  /https:\/\/(?:(?<subdomain>[^.]+)(?<dot>\.))?(?<domain>[^.]+)\.(?<tld>[a-z]+)\//,
  '$<tld>.$<domain>$<dot>$<subdomain>://$<subdomain>$<dot>$<domain>.$<tld>'
);

const DeepLinks = ({ customAsPath }) => {
  const { t } = useTranslation();
  const { locale, asPath: routerAsPath } = useRouter();
  const asPath = typeof customAsPath === 'undefined' ? `/${locale}${routerAsPath}` : customAsPath;

  const mobileDeepLinkUrl = `${deepLinkUrlPart}${asPath}${asPath === '/' ? 'main-app/' : ''}`;
  const deepLinkAppName = t('deepLinks.appName', 'Chicorée Fashion');

  return (
    <Head>
      <meta
        property="al:ios:url"
        content={mobileDeepLinkUrl}
      />
      <meta
        property="al:ios:app_store_id"
        content={IOS_APP_STORE_ID}
      />
      <meta
        property="al:ios:app_name"
        content={deepLinkAppName}
      />

      <meta
        property="al:android:url"
        content={mobileDeepLinkUrl}
      />
      <meta
        property="al:android:package"
        content={ANDROID_PACKAGE_NAME}
      />
      <meta
        property="al:android:app_name"
        content={deepLinkAppName}
      />

      <meta
        property="og:type"
        content="website"
      />
    </Head>
  );
};

DeepLinks.propTypes = {
  customAsPath: PropTypes.string,
};

DeepLinks.defaultProps = {
  customAsPath: undefined,
};

export default DeepLinks;
