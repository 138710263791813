import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { LoaderStyled, LoadingContainer } from './LoadingIndicator.styled';

const LoadingIndicator = ({
  color, isLarge, withWrapper, delayShow, spacingTop, size,
}) => {
  const LoaderWrapper = withWrapper ? LoadingContainer : Fragment;

  return (
    <LoaderWrapper>
      <LoaderStyled
        data-testid="loadingIndicator"
        $color={color}
        isLarge={isLarge}
        size={size}
        delayShow={delayShow}
        spacingTop={spacingTop}
      />
    </LoaderWrapper>
  );
};

LoadingIndicator.propTypes = {
  color: PropTypes.string,
  isLarge: PropTypes.bool,
  withWrapper: PropTypes.bool,
  delayShow: PropTypes.bool,
  size: PropTypes.number,
  spacingTop: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

LoadingIndicator.defaultProps = {
  color: '',
  isLarge: false,
  withWrapper: false,
  delayShow: false,
  spacingTop: null,
  size: null,
};

export default LoadingIndicator;
